import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux';
import { auth } from '../../utils/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faCogs } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/curral-circle.png';

import Staff from './types/staff';
import Client from './types/client';
import Other from './types/other'

import './navigation.scss';
import { Redirect } from 'react-router-dom';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Update the redirect state to null to avoid the navigation not loading
     *
     * @function
     */
    updateRedirectState = () => {
        this.setState({ redirect: null });
    }


    /**
     * Navigate the user to the given path by updating the state
     *
     * @function
     *
     * @param {String} url URL of the path to navigate to
     */
    navigate = (url) => {
        this.setState({ redirect: `/${url}` }, () => {
            /**
             * Update the redirect state value again
             */
            this.updateRedirectState();
        });
    }

    /**
     * Render function
     *
     * @function
     */
    render() {
        console.log(this.props.user, 'THE USER TYPE')
        // this.props.user.is_admin = true;
        if (this.state.redirect && (this.state.redirect !== window.location.pathname)) {
            return (<Redirect to={this.state.redirect} />);
        }
        // console.log(this.props,"PROPS NAV")

        return (
            <nav className="navigation">
                <div className="nav-logo">
                    <img src={logo} alt="Curral Circle Logo" />
                </div>
                <div className="nav-menu">
                    {this.props.user.type === 'staff' && <Staff navigate={this.navigate} />}
                    {this.props.user.type === 'client' && <Client navigate={this.navigate} />}
                    {/* {this.props.user.type === undefined && <Other navigate={this.navigate} />} */}
                    {/* {this.props.user.type === '-' && <Other navigate={this.navigate} />} */}
                    {/* {this.props.user.type === undefined && <Client navigate={this.navigate} />} */}
                    {/* {this.props.user.type === 'client' && <Client navigate={this.navigate} />} */}

                </div>
                <div className="nav-lower">
                    {(
                        this.props.user.is_admin
                    ) &&
                        <div className="nav-item settings" onClick={() => this.navigate('settings')}>
                            <FontAwesomeIcon icon={faCogs} />
                        </div>
                    }
                    <div className="nav-item logout" onClick={() => auth.signOut()}>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user
    };
}

export default connect(mapStateToProps, null)(Navigation);
import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import firebase from "firebase";
import moment from "moment";
import "./leads.scss";
// import './leadsA.css';

/**
 * UI components
 */
import Lead from "./lead/lead";
import Button from "../../../components/ui/button/button";
import ViewLead from "../../../components/modal/types/view-lead";
import ViewSDR from "../../../components/modal/types/view-sdr";
import ViewFilter from "../../../components/modal/types/view-filter";
import { action_types, lead_types } from "../../../utils/selects";
import Datetime from 'react-datetime';
import 'moment/locale/en-au';
import { CSVLink, CSVDownload } from "react-csv";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from '../../../store/actions';
import { useLayoutEffect } from "react";
// import 'moment/locale/es'  // without this line it didn't work


/**
 * Functional component to return the leads for a given client
*/
function Leads(props) {
    const moment = require('moment');
    moment.locale('en-au')
    const [leads, setLeads] = useState([]);
    const [inOrderLeads, setInOrderLeads] = useState([]);
    const [sortedLeads, setSortedLeads] = useState([]);
    const [monthsBack, setMonthsBack] = useState(2);
    const [showingLead, setShowingLead] = useState(false);
    const [showingSDR, setShowingSDR] = useState(false);
    const [lead, setLead] = useState({});
    const [filterState, setFilterState] = useState({
        name: '',
        leadtype: '',
        from: '',
        till: ''

    })
    const [message, setMessage] = useState(" ")
    const [leadsCommplete, setLeadsComplete] = useState(false)


    const users = useSelector(state => state?.user?.myUser)
    console.log(users,"USEERASD")
    const allUsers = useSelector(state => state.user.allUsers)
    const dispatch = useDispatch()
    /**
     * Deconstruct the clientID from the props
     */
    const { client } = props;

    /**
     * Generate a timestamp from the first of the previous month to collect leads from
     */
    const startFrom = moment().startOf("month").subtract(monthsBack, "months").format("x");


    const reset = () => {
        if (filterState.name || filterState.leadtype || filterState.from || filterState.till) {
            setFilterState({
                name: '',
                from: '',
                leadtype: '',
                till: ''
            })
        }
        if (monthsBack > 2) {
            setMonthsBack(2)
        }
    }
    const dismiss = () => {
        setShowingLead(false)
    }
    /**
     * On component load
     */
    useEffect(() => {
        setLeads([]);
        setMessage("Loading...")
        const startTimestamp = firebase.firestore.Timestamp.fromMillis(startFrom);
        const queryss = async () => {
            let query = db.collection(`clients/${client}/leads`)
            let isFilterFromTill = false;

            if (filterState.name) {
                query = query.where("saved_by", "==", filterState.name)
            }
            if (filterState.leadtype) {
                query = query.where("type", "==", filterState.leadtype)
            }
            if (filterState.from) {
                isFilterFromTill = true;
                let from = '', till = '';
                if (filterState.from) {
                    isFilterFromTill = true;
                    from = new Date(0);
                    from.setUTCSeconds(filterState?.from?.seconds);
                    // from.setTime(from.getTime() - 24 * 60 * 60 * 1000);
                    from = firebase.firestore.Timestamp.fromDate(from);
                    query = query.where("created", ">=", from);
                    setMessage("no record found")

                }
                if (filterState.till) {

                    isFilterFromTill = true;
                    till = new Date(0);
                    till.setUTCSeconds(filterState?.till?.seconds);
                    till.setTime(till.getTime() + 24 * 60 * 60 * 1000);
                    till = firebase.firestore.Timestamp.fromDate(till);
                    query = query.where("created", "<=", till);
                    setMessage("no record found")
                }
                // if (from) {
                //     setMessage("no record found")
                //     query = query.where("created", ">=", from);

                // } else if (till) {
                //     setMessage("no record found")
                //     query = query.where("created", "<=", till);

                // }
            }
            if (startTimestamp && !isFilterFromTill && filterState.name == "" && filterState.leadtype == "") {
                query = query.where("updated", ">=", startTimestamp)
            }

            query.onSnapshot(querySnapshot => {
                console.log(querySnapshot.size, "SIZE")
                if (querySnapshot.size) {
                    setLeads(querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    })));
                }
                else {
                    setMessage("No record found")
                }

            });


        }
        queryss();
    }, [
        client,
        monthsBack,
        filterState,
        props.up
    ]);
    useLayoutEffect(() => {
        // console.log("allusers get")
        dispatch({ type: actionTypes.getUsers })
        dispatch({ type: actionTypes.getAllUsers })
    }, [])

    const handleChange = (e) => {
        const value = e.target.value;

        setFilterState({
            ...filterState,
            [e.target.name]: value
        })
    }

    const handleDatechangeFrom = (date) => {
        const readable = moment(date).format('DD/MM/YYYY HH:mm');
        const seconds = moment(date).format('X');
        setFilterState({
            ...filterState,
            from: {
                seconds: seconds,
                readable: readable
            }
        })

    }
    const handleDatechangeTill = (date) => {
        const readable = moment(date).format('DD/MM/YYYY HH:mm');
        const seconds = moment(date).format('X');
        setFilterState({
            ...filterState,
            till: {
                seconds: seconds,
                readable: readable
            }
        })


    }

    /**
     * 
     */

    useEffect(() => {
        const toSort = [...leads.filter((lead, index, self) =>
            self.findIndex(l => l.id === lead.id) === index
        )]

        toSort.sort(sortDateType);
        setInOrderLeads(toSort);


    }, [leads]);
    /**
     * Sort the leads first by type (Hot, Moderate, Low) and then by creation
     * date within that
     */
    const sortDateType = (a, b) => {
        console.log("sortedDAta")

        /**
         * If the dates are the same, sort by the type
         */
        if (a.type > b.type) {
            return 1;
        }
        if (a.type < b.type) {
            return -1;
        }

        /**
         * Sort by the date the lead was recorded
         */
        if (a.updated && b.updated) {
            const date = b.updated.seconds - a.updated.seconds;
            if (date !== 0) {
                return date;
            }
            return 0;
        }
        return 0;
    }

    /**
     * Sort the different leads into month blocks for displaying on the front end
     */
    useEffect(() => {
        /**
         *
         */

        let leadsUI = [];
        /**
         *
         */
        inOrderLeads.forEach((lead, index) => {
            /**
             *
             */
            if (lead.updated) {
                /**
                 * Get the year and month for the given lead
                 */
                const year = moment(lead.updated.seconds, "X").format("YYYY");
                const month = moment(lead.updated.seconds, "X").format("MM");
                /**
                 * Make sure there is a year element available
                 */
                if (!leadsUI[`${year}`]) {
                    leadsUI[`${year}`] = [];
                    for (let i = 1; i <= 12; i++) {
                        leadsUI[`${year}`][i] = [];
                    }
                }
                /**
                 * Then push the lead to that month
                 */
                leadsUI[`${year}`][`${parseInt(month, 10)}`].push(lead);
            } else {
                /**
                 *
                 */
                if (!leadsUI["Uncategorized"]) {
                    leadsUI["Uncategorized"] = [];
                }
                /**
                 *
                 */
                leadsUI["Uncategorized"].push(lead);
            }
        });
        /**
         * 
         */
        const newLeads = leadsUI.filter((ls) => {
            delete ls[0];
            return ls;
        })
        setSortedLeads(newLeads);
    }, [inOrderLeads]);

    const changeCSVdata = (leadsData) => {
        let newLeads = []
        leadsData.forEach((lead, index) => {
            let usrMatch;
            for (let j = 0; j < users.length; j++) {
                if (users[j].id === lead.saved_by) {
                    usrMatch = users[j];
                    break;
                }
            }
            if (usrMatch !== undefined) {
                let newLead = { ...lead };
                newLead.saved_by = usrMatch.name;
                // newLead.init_engagement = '';
                delete newLead.init_engagement
                newLead.id = index + 1;
                if (newLead.hasOwnProperty('created')) {
                    let d = new Date(0);
                    d.setUTCSeconds(newLead.created);
                    newLead.created = d;
                }
                if (newLead.hasOwnProperty('updated')) {
                    let d = new Date(0);
                    d.setUTCSeconds(newLead.updated);
                    newLead.updated = d;
                }
                newLeads.push(newLead);
            }
        });
        return newLeads;
    }
    return (
        <>
            {/* View lead modal */}
            <ViewLead
                active={showingLead}
                client={client}
                lead={lead}
                dismiss={() => setShowingLead(false)}
            />
            {/* View SDR modal */}
            <ViewSDR
                active={showingSDR}
                client={client}
                lead={lead}
                dismiss={() => setShowingSDR(false)} />

            <div className="parentclass">
                <div style={{ display: 'flex', }} >

                    <div style={{ margin: "6px" }} >

                        <select name="name" onChange={handleChange}
                            value={filterState.name}

                            style={{ padding: "8px 12px", color: "white", backgroundColor: "#3e3e3e", border: "solid 2px #3e3e3e", width: '200px' }}
                        // value={"User"}
                        >
                            <option value="" >USER</option>
                            {
                                Array.isArray(users)
                                    ?
                                    (<>

                                        {
                                            users?.map((usr, ind) => {
                                                return (
                                                    <option
                                                        key={ind}
                                                        style={{ color: "white", backgroundColor: "#3e3e3e" }}
                                                        value={usr.id} >{usr.name}</option>
                                                )
                                            })
                                        }
                                    </>)
                                    :
                                    (<>

                                    </>)

                            }

                        </select>
                    </div>

                    <div style={{ margin: "5px" }}>

                        <select name="leadtype" onChange={handleChange}
                            value={filterState.leadtype}
                            style={{ padding: "8px", color: "white", backgroundColor: "#3e3e3e", border: "solid 2px #3e3e3e" }}

                        //  value={filterState.leadtype} 
                        >
                            <option value="" >Lead Type</option>
                            {
                                lead_types ?
                                    (<>
                                        {
                                            Object.keys(lead_types).map((val, key) => {
                                                return (
                                                    <option
                                                        key={key}
                                                        style={{ color: "white", backgroundColor: "#3e3e3e" }}
                                                        value={lead_types[val]} >{val}</option>
                                                )
                                            })
                                        }
                                    </>) : (<></>)
                            }
                        </select>
                    </div>

                    <div style={{ margin: "5px" }}>

                        {/* <label>From</label> */}
                        <Datetime
                            value={filterState.from?.readable}
                            onChange={handleDatechangeFrom}
                            name="from"
                            inputProps={{ placeholder: 'From' }}

                        />
                    </div>
                    <div style={{ margin: "5px" }}>

                        {/* <label>till</label> */}
                        {/* <input type="date" />
                         */}

                        <Datetime
                            value={filterState.till?.readable}
                            onChange={handleDatechangeTill}
                            name="till"
                            inputProps={{ placeholder: 'Till' }}

                        />
                    </div>
                    <div style={{ margin: "6px" }} >
                        {/* <button
                            className="filterBtn"
                            style={{ cursor: 'pointer' }}
                            // style={{ color: "white", backgroundColor: "#3e3e3e", padding: "11px 60px", cursor: "pointer", }}
                            onClick={filter} >Filter</button> */}

                        <button
                            className="filterBtn"
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                            // style={{ color: "white", backgroundColor: "#3e3e3e", padding: "11px 60px", marginLeft: '10px', cursor: "pointer" }}
                            onClick={reset} >Reset</button>


                        <CSVLink filename="All" data={changeCSVdata(leads)} >
                            <button
                                className="filterBtn"
                                style={{ marginLeft: '5px', cursor: 'pointer' }}
                            >Download</button>
                            {/* <h4>Download</h4> */}
                        </CSVLink>
                    </div>
                </div>
            </div>

            <div>
                {/* {console.log(sortedLeads, "SORTED LEAEDS")} */}
                {
                    sortedLeads?.length > 0 ?
                        (
                            <div>
                                {sortedLeads?.reverse().map((months, year) => {

                                    return (
                                        <div key={year}>
                                            {months.slice(0).reverse().map((leads, month) => {
                                                let fname = "";
                                                if (leads[year]?.created?.seconds === undefined) {
                                                    fname = moment.unix(leads[month]?.updated?.seconds).format("MMMM YYYY")
                                                } else {
                                                    fname = moment.unix(leads[year]?.updated?.seconds).format("MMMM YYYY")
                                                }


                                                const dat = changeCSVdata(leads)
                                                return (
                                                    <div key={`${year}${month}`}>
                                                        {leads?.length > 0 &&


                                                            (
                                                                <>
                                                                    <div key={`${year}${month}`} className="lead-list-block">
                                                                        <div className="lead-list-header" >
                                                                            <p className="header-month">
                                                                                {fname}
                                                                                {/* {moment.unix(leads[month]?.created?.seconds).format("MMMM YYYY")} */}
                                                                            </p>
                                                                            <hr />
                                                                            <CSVLink
                                                                                // className="filterBtn"
                                                                                className="downloadbtn"

                                                                                // style={{ cursor: 'pointer' , color:"red"}}
                                                                                filename={fname}
                                                                                data={dat}
                                                                            >
                                                                                <FontAwesomeIcon style={{ marginLeft: "20px" }} icon={faDownload} className="fa-lg" />
                                                                            </CSVLink>
                                                                        </div>
                                                                        <div>
                                                                            <p className="header-month">
                                                                                {/* {moment(12 - month, "MM").format("MMMM")} {moment().subtract(year, "years").format("YYYY")} */}
                                                                            </p>
                                                                            <hr />

                                                                        </div>
                                                                        {leads?.map((lead, index) => (
                                                                            <>
                                                                                <Lead
                                                                                    key={lead.id}
                                                                                    id={lead.id}
                                                                                    data={lead}
                                                                                    edit={(lead) => {
                                                                                        setLead(lead);
                                                                                        setShowingLead(true);
                                                                                    }}
                                                                                    viewSDR={(lead) => {
                                                                                        setLead(lead);
                                                                                        setShowingSDR(true);
                                                                                    }} />
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                </>)


                                                        }

                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>

                        )
                        :
                        (<>
                            <div style={{ textAlign: 'center' }} >
                                <p style={{ fontSize: "20px", fontWeight: 800 }} >{message}</p>
                            </div>
                        </>)
                }

                {filterState.name || filterState.from || filterState.till || filterState.leadtype ? (<></>) : (
                    <>
                        <Button
                            class="load-more-months"
                            onClick={() => setMonthsBack(monthsBack + 1)}>
                            Load another month
                        </Button>
                    </>
                )}

            </div>
        </>
    );
}

export default Leads;
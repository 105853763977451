import React from "react";
import moment from "moment";

/**
 * UI Elements
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

/**
 * Functional component to return the lead table for the given client
 */
function Lead(props) {
    /**
     * Pull the lead ID and data from the props
     */
    const { id, data } = props;

    /**
     * If this is an SDR report to print
     */
    if (data.type === "1_SDR") {
        return (
            <div key={id} className="lead-item" onClick={() => props.viewSDR(data)}>
                <div className="li date" style={{ width: '12rem' }}>
                    {data.record_date && <p>{moment.unix(data.record_date.seconds).format("DD/MM/YYYY")}</p>}
                    {!data.record_date && <p>{moment.unix(data.updated.seconds).format("DD/MM/YYYY")}</p>}
                </div>
                <div className="li type">
                    <FontAwesomeIcon icon={faPencilAlt} />
                </div>
                <div className="li name"  style={{ width: '12rem' }}  >
                    <p  >
                        {data.telesales_dials} Dials ({data.telesales_talktime})
                    </p>
                </div>
                <div className="li job"  style={{ width: '12rem' }}>
                    <p>{data.summary_mqls} MQLs</p>
                </div>
                <div className="li company" style={{ width: '12rem' }}>
                    <p>{data.summary_appointments} Appointments</p>
                </div>
                <div className="li init_msg" style={{ width: '12rem' }}>
                    <p>{data.summary_notes || "No engagement yet"}</p>
                </div>
                <div className="li toggle" >
                    <FontAwesomeIcon icon={faCaretRight} />
                </div>
            </div>
        );
    } else {
        /**
         * If this is a normal lead item
         */
        let init_message = "";
        if(data.init_message){
            if(data.init_message.length > 30){
                init_message = `${data.init_message.slice(0, 30)}...`;
            }else{
                init_message = data.init_message;
            }
        }else{
            init_message = "No engagement yet";
        }
        return (
            <div key={id} className="lead-item" onClick={() => props.edit(data)}>
                <div className="li date" style={{ width: '12rem' }}>
                    {data.lead_created && <p>{data.lead_created && moment.unix(data.lead_created).format("DD/MM/YYYY")}</p>}
                    {!data.lead_created && <p>{data.updated && moment.unix(data.updated.seconds).format("DD/MM/YYYY")}</p>}
                </div>
                <div className="li type" style={{ width: '12rem' }}>
                    <div className={["type-indicator", data.type?.substring(2)].join(" ")} />
                </div>
                <div className="li name" style={{ width: '12rem' }}>
                    {data.name.length > 30 ? (<p>{data.name.slice(0, 30)}...</p>) : (<p>{data.name}</p>)}
                </div>
                <div className="li job " style={{ width: '15rem' }}>
                    {data.job_title.length > 30 ? (<p>{data.job_title.slice(0, 30)}...</p>) : (<p>{data.job_title}</p>)}
                    {/* <p>{data.job_title}</p> */}
                </div>
                <div className="li company" style={{ width: '15rem' }}>
                    {data.company.length > 30 ? (<p>{data.company.slice(0, 30)}...</p>) : (<p>{data.company}</p>)}
                    {/* <p>{data.company}</p> */}
                </div>
                <div className="li init_msg" style={{ width: '15rem' }}>
                    <p>{init_message}</p>
                </div>
                <div className="li toggle">
                    <FontAwesomeIcon icon={faCaretRight} />
                </div>
            </div>
        );
    }
}

export default Lead;
import React, { Component } from 'react';
import firebase from 'firebase';
import { db } from '../../utils/firebase';

/**
 * Components
 */
import Input from '../ui/input/input';
import Button from '../ui/button/button';

class AddClient extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Save client with new data
     *
     * @function
     */
    saveClient = () => {
        /**
         * Deconstruct the client data from state
         */
        const { name } = this.state;
        /**
         * Update the lead document
         */
        db.collection(`clients`).add({
            name: name,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            updated: firebase.firestore.FieldValue.serverTimestamp()
        });
        /**
         * Dismiss the edit modal
         */
        this.props.dismiss();
    }

    /**
     * Handle the event of an input change
     *
     * @function
     *
     * @param {Object} e Event object
     */
    handleInputChange = (e) => {
        /**
         * Update the state with the field
         */
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
            <div className={['modal-form show'].join(' ')}>
                <Input
                    id="name"
                    placeholder="Client name"
                    value={this.state.name}
                    onChange={this.handleInputChange} />
                <Button onClick={this.saveClient}>Save Client</Button>
            </div>
        );
    }
}

export default AddClient;
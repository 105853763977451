import React, { Component } from 'react';
import firebase from 'firebase';
import { db, fun, arrayUnion } from '../../utils/firebase';

/**
 * Components
 */
import Input from '../ui/input/input';
import Checkbox from '../ui/input/checkbox';
import Button from '../ui/button/button';

class AddStakeholder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: false
        };
    }

    /**
     * Attempt to setup a new stakeholder for the client
     *
     * @function
     */
    setupStakeholder = async () => {
        /**
         * Deconstruct the data from state
         */
        const { name, email, notifications } = this.state;
        /**
         * Deconstruct the clientID from props
         */
        const { client } = this.props;
        /**
         * Check to see if the user already exists on the platform
         */
        const exists = await this.checkExistsing(email);
        /**
         * Does this user exist?
         */
        if (exists) {
            /**
             * Add the new user to the database
             */
            await db.doc(`users/${exists}`).set({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                updated: firebase.firestore.FieldValue.serverTimestamp(),
                name,
                email,
                clients: arrayUnion(client.id)
            }, { merge: true });
            /**
             * Save the user in the clients stakeholders collection
             */
            await db.doc(`clients/${client.id}/stakeholders/${exists}`).set({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                updated: firebase.firestore.FieldValue.serverTimestamp(),
                notifications
            }, { merge: true });
        } else {
            /**
             * If they don't, send the data off to a cloud function where they'll have an auth
             * account created, be sent an email with a password and be granted access to the client
             */
            const createUser = fun.httpsCallable('createUserAuthAccount');
            const userResult = await createUser({
                name,
                email,
                client: client.id,
                notifications: notifications
            });
            return userResult;
        }
        /**
         * Hide the form
         */
        this.props.dismiss();
    }

    /**
     * Check to see if the user being added is already setup with an auth account
     *
     * @function
     */
    checkExistsing = async (email) => {
        /**
         * Call the database for potential matches
         */
        return await db.collection('users').where('email', '==', email).get().then((users) => {
            if (users.size > 0) {
                return users.docs[0].id;
            } else {
                return false;
            }
        });
    }

    /**
     * Handle the event of an input change
     *
     * @function
     *
     * @param {Object} e Event object
     */
    handleInputChange = (e) => {
        /**
         * Update the state with the field
         */
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    /**
     * Handler for the notification checkbox
     *
     * @function
     */
    handleNotificationToggle = () => {
        this.setState({
            ...this.state,
            notifications: !this.state.notifications
        });
    }

    render() {
        // console.log(this.props)
        return (
            <div className={['modal-form', this.props.active ? 'show' : ''].join(' ')}>
                <Input
                    id="name"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={this.handleInputChange} />
                <Input
                    id="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleInputChange} />
                <Checkbox
                    id="notifications"
                    label="Receives notifications of new leads"
                    toggle={this.handleNotificationToggle} />
                <Button onClick={this.setupStakeholder}>Save Stakeholder</Button>
            </div>
        );
    }
}

export default AddStakeholder;
import React, { Component } from 'react';
import firebase from 'firebase';
import { db, auth } from '../../utils/firebase';
import { action_types } from '../../utils/selects';

/**
 * Datetime picker
 */
import Datetime from 'react-datetime';

/**
 * Components
 */
import Input from '../ui/input/input';
import Select from '../ui/input/select';
import TextArea from '../ui/input/textarea';
import Checkbox from '../ui/input/checkbox';
import Button from '../ui/button/button';

/**
 * Moment libary
 */
const moment = require('moment');

class AddAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cron: false
        };
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        this.fetchStaffList();
        document.addEventListener('mousedown', this.clickedOutsideStaffSelect);
        document.addEventListener('mousedown', this.clickedOutsideTypeSelect);
    }

    /**
     * Component unmount
     *
     * @function
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickedOutsideStaffSelect);
        document.removeEventListener('mousedown', this.clickedOutsideTypeSelect);
    }

    /**
     * Wrapper refs for the staff select field
     */
    setStaffRef = node => {
        this.staffRef = node;
    }

    clickedOutsideStaffSelect = (e) => {
        if (this.staffRef && !this.staffRef.contains(e.target)) {
            this.setState({
                ...this.state,
                selecting_lead_type: false
            });
        }
    }

    toggleStaffSelect = () => {
        this.setState({
            selecting_staff: !this.state.selecting_staff
        });
    }

    /**
     * Wrapper refs for the type select field
     */
    setTypeRef = node => {
        this.typeRef = node;
    }

    clickedOutsideTypeSelect = (e) => {
        if (this.typeRef && !this.typeRef.contains(e.target)) {
            this.setState({
                ...this.state,
                selecting_lead_where: false
            });
        }
    }

    toggleTypeSelect = () => {
        this.setState({
            selecting_type: !this.state.selecting_type
        });
    }

    /**
     * Fetch a list of staff members for showing in the assignable list
     *
     * @function
     */
    fetchStaffList = () => {
        /**
         * Get the users from firestore where they have the staff type
         */
        db.collection('users').where('type', '==', 'staff').get().then((staffDocs) => {
            /**
             * Setup an empty object
             */
            let staffArr = {};
            /**
             * Push each of the staff members onto it
             */
            staffDocs.forEach(staff => {
                staffArr[staff.data().name] = staff.id
            });
            /**
             * Update the state with the staff list
             */
            this.setState({ staff_list: staffArr });
        });
    }

    /**
     * Schedule the action in the database
     *
     * @function
     */
    scheduleAction = () => {
        /**
         * Reset errors
         */
        this.setState({ error: '' });
        /**
         * Get the client and lead data from props
         */
        const { client, lead } = this.props;
        /**
         * Get the engagement data from the state
         */
        const { title, message, type, action_on, cron, staff } = this.state;
        /**
         * If there are missing values, don't continue
         */
        if (!title || !message || !type || !action_on) {
            this.setState({ error: 'Please fill in all fields before continuing.' });
        } else {
            /**
             * If these values all exist, add the engagement to the lead document
             */
            db.collection(`clients/${client}/leads/${lead}/actions`).add({
                title: title || 'Title not set',
                message: message || 'No comments',
                type: type || 'Unknown type',
                action_on,
                staff: this.props.staff ?
                    staff ?
                        staff : auth.currentUser.uid
                    : auth.currentUser.uid,
                cron,
                setup_by: auth.currentUser.uid,
                created: firebase.firestore.FieldValue.serverTimestamp()
            });
            /**
             * If cron was checked, also schedule and action in that collection for pubsub
             */
            if (cron) {
                db.collection(`scheduled`).add({
                    client, lead, title, message, type, action_on,
                    staff: this.props.staff ?
                        staff ?
                            staff : auth.currentUser.uid
                        : auth.currentUser.uid, cron,
                    setup_by: auth.currentUser.uid,
                    created: firebase.firestore.FieldValue.serverTimestamp()
                });
            }
            /**
             * Hide the form
             */
            this.props.dismiss();
        }
    }

    /**
     * Handle the event of an input change
     *
     * @function
     *
     * @param {Object} e Event object
     */
    handleInputChange = (e) => {
        /**
         * Update the state with the field
         */
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    /**
     * Update the state with a formatted verison of the datetime picker result
     *
     * @function
     *
     * @param {Object} date Date object from the datetime picker
     */
    handleDateChange = (date) => {
        /**
         * Parse the date into different string types
         */
        const readable = moment(date).format('DD/MM/YYYY HH:mm');
        const seconds = moment(date).format('X');
        /**
         * Then set the state with them
         */
        this.setState({
            ...this.state,
            action_on: {
                seconds: seconds,
                readable: readable
            }
        });
    }

    /**
     * Change the state when the actions type changes
     *
     * @function
     *
     * @param {*} value
     */
    handleTypeChange = (value) => {
        this.setState({
            ...this.state,
            type: value.option,
            selecting_type: false
        });
    }

    /**
     * Change the state when a staff member has been selected
     *
     * @function
     *
     * @param {*} value
     */
    handleStaffChange = (value) => {
        this.setState({
            ...this.state,
            staff: value.value,
            action_staff_name: value.option,
            selecting_staff: false
        });
    }

    /**
     * Handler for the cron checkbox
     *
     * @function
     */
    handleCronToggle = () => {
        this.setState({
            ...this.state,
            cron: !this.state.cron
        });
    }

    render() {
        return (
            <div className={['modal-form engagement-form', this.props.active ? 'show' : ''].join(' ')}>
                <Input
                    id="title"
                    placeholder="Title"
                    value={this.state.title}
                    onChange={this.handleInputChange} />
                <TextArea
                    id="message"
                    placeholder="What needs to be done?"
                    value={this.state.message}
                    onChange={this.handleInputChange} />
                <div className="inputfield" ref={this.setTypeRef} >
                    <Select
                        placeholder="Action type"
                        options={action_types}
                        value={this.state.type}
                        changed={this.handleTypeChange}
                        toggled={this.state.selecting_type}
                        toggle={this.toggleTypeSelect} />
                </div>
                {this.props.staff &&
                    this.state.staff_list &&
                    <div className="inputfield" ref={this.setStaffRef} >
                        <Select
                            placeholder="Assign to"
                            options={this.state.staff_list}
                            value={this.state.action_staff_name}
                            changed={this.handleStaffChange}
                            toggled={this.state.selecting_staff}
                            toggle={this.toggleStaffSelect} />
                    </div>}
                <Datetime
                    inputProps={{ placeholder: 'Action on' }}
                    onChange={date => this.handleDateChange(date)} />
                <Checkbox
                    id="cron"
                    label="Remind me 1 hour before"
                    toggle={this.handleCronToggle} />
                <Button onClick={this.scheduleAction}>Schedule Action</Button>
                {this.state.error &&
                    <p className="adding-error">{this.state.error}</p>}
            </div>
        );
    }
}

export default AddAction;
// Import action types
import { db } from '../../utils/firebase';
import * as actionTypes from '../actions';

// Setup an inital state
const initState = {
    user: [],
    myUser:[],
    allUsers: [],
    Leads:[],
    showingLead:false,
    showingSDR:false
    // filter: null
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                // is_admin:true
            }
        case actionTypes.Filter:
            return {
                ...state,
                filter: action.payload
            }
        case actionTypes.getUsers:
            let myUser = []
            db.collection('users').where('type', '==', 'staff').get().then((staffDocs) => {
                
                // let staffArr = {};
                
                staffDocs.forEach(staff => {
                    // staffArr[staff.data().name] = staff.id
                    myUser.push({
                        id:staff.id,
                        name:staff.data().name
                    })
                });
            });
            return {
                ...state, myUser
            }
        case actionTypes.getAllUsers:
            let allUsers = []
            db.collection(`users`).get().then((userDocs) => {
                userDocs.docs.forEach(usr => {
                    allUsers.push({
                        id: usr.id,
                        name: usr.data().name
                    })

                });
            })

            return {
                ...state, allUsers
            }
        case actionTypes.getAllLeads:
            let Leads =[]
            console.log(action.payload,"ACTION")
            db.collection(`clients/${action.payload.client}/leads`)
                .where("updated",">=", action.payload.startTimestamp)
                .onSnapshot((leadsSnap)=>{
                    leadsSnap.forEach((change)=>{
                        // console.log(change,"LEAD SNAPS")
                        Leads.push({
                            id:change.id,
                            ...change.data()
                        })

                    })
                })
                // console.log(Leads,"LEADS SDASD")
                return {
                    ...state, Leads
                }
        case actionTypes.showingLead:{
            return{
                ...state,
                showingLead:action.payload
            }
        }
        case actionTypes.showingSDR:{
            return{
                ...state,
                showingSDR:action.payload
            }
        }
        default:
            return state;
    }
};

export default reducer;


//             .where("updated", ">=", startTimestamp)
//             .onSnapshot((leadsSnap) => {
//                 /**
//                  * Loop over the lead documents found
//                  */
//                 leadsSnap.docChanges().forEach((change) => {
//                     /**
//                      * Lead added
//                      */
//                     if (change.type === "added") {
//                         setLeads((leads) => [
//                             ...leads,
//                             {
//                                 id: change.doc.id,
//                                 ...change.doc.data(),
//                             }
//                         ]);
//                     }
//                     /**
//                      * Lead updated
//                      */
//                     if (change.type === "modified") {
//                         setLeads((leads) => {
//                             let updatedLeads = [...leads];
//                             for (let i in leads) {
//                                 if (leads[i].id === change.doc.id) {
//                                     updatedLeads[i] = {
//                                         id: change.doc.id,
//                                         ...change.doc.data(),
//                                     };
//                                     break;
//                                 }
//                             }
//                             return updatedLeads;
//                         });
//                     }
//                     /**
//                      * Lead removed
//                      */
//                     if (change.type === "removed") {
//                         setLeads((leads) => leads.filter((lead) => lead.id !== change.doc.id));
//                     }
//                 });
//             });
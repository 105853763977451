import React, { Component } from 'react';
import { db } from '../../utils/firebase';

/**
 * Components
 */
import Button from '../ui/button/button';
import AddEngagement from '../forms/add-engagement';

/**
 * Moment libary for parsing dates on the UI
 */
const moment = require('moment');

class Engagements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adding: false,
            client: '',
            lead: '',
            default_engagements: []
        };
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        this._isMounted = true;
        this.checkforUpdate();
    }

    /**
     * Component update
     *
     * @function
     */
    componentDidUpdate() {
        this.checkforUpdate();
    }

    /**
     * Component unmount
     *
     * @function
     */
    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Check for component load
     *
     * @function
     */
    checkforUpdate = () => {
        /**
         * Get the values from props
         */
        const { client, lead } = this.props;
        /**
         * If the prop values don't match the state
         */
        if (client !== this.state.clientID || lead !== this.state.leadID) {
            /**
             * Update teh state with the new IDs
             */
            this.setState({ clientID: client, leadID: lead }, () => {
                /**
                 * Then fetch the engagements for these values
                 */
                this.fetchEngagements(client, lead);
            });
        }
    }

    /**
     * Fetch the engagements for a particular client and lead
     *
     * @function
     *
     * @param {String} client ID string for the client
     * @param {String} lead ID string for the lead
     */
    fetchEngagements = (client, lead) => {
        /**
         * Setup a snapshot listener on the leads engagements collection
         */
        db.collection(`clients/${client}/leads/${lead}/engagements`).onSnapshot((engagementsSnap) => {
            /**
             * Init a new empty function on each new snapshot
             */
            let engagements = [...this.state.default_engagements] || [];
            /**
             * Loop over the engagements collected
             */
            engagementsSnap.docChanges().forEach(async (change) => {
                if (change.type === 'added') {
                    engagements.push({ id: change.doc.id, ...change.doc.data() });
                }
                if (change.type === 'modified') {
                    engagements = engagements.filter(lead => lead.id !== change.doc.id);
                    engagements.push({ id: change.doc.id, ...change.doc.data() });
                }
                if (change.type === 'removed') {
                    engagements = engagements.filter(lead => lead.id !== change.doc.id);
                }
                /**
                 * Update the state defaults
                 */
                this.setState({ default_engagements: engagements }, () => {
                    /**
                     * Sort the leads before appending to state
                     */
                    engagements.sort(this.sortByDate);
                    /**
                     * Then organise them into months for the UI
                     */
                    this._isMounted && this.setState({ engagements });
                })
            });
        });
    }

    /**
     * Sort the engagements by their date
     *
     * @function
     */
    sortByDate(a, b) {
        /**
         * Sort by the date the lead was recorded
         */
        const date = b.created.seconds - a.created.seconds;
        if (date !== 0) {
            return date;
        }
        return 0;
    }

    /**
     * Toggle the state to show and hide the add form
     *
     * @function
     */
    toggleAdding = () => {
        /**
         * Switch the state
         */
        this.setState({ adding: !this.state.adding });
    }

    /**
     *
     */
    render() {
        /**
         * If there are engagements loaded in the state
         */
        if (this.state.engagements) {
            return (
                <div className={['modal-form', this.props.active ? 'show' : ''].join(' ')}>
                    {!this.state.adding &&
                        <Button onClick={this.toggleAdding}>Record Engagement</Button>}

                    {/* Add engagement form */}
                    {this.state.adding &&
                        <AddEngagement
                            client={this.props.client}
                            lead={this.props.lead}
                            active={this.state.adding}
                            dismiss={this.toggleAdding} />}

                    {this.state.engagements.map((engagement, index) => (
                        <div key={engagement.id} className="engagement-item">
                            <div className="engagement-date">
                                <p>{engagement.created && moment.unix(engagement.created.seconds).format('DD/MM/YYYY')}</p>
                                <small>{engagement.created && moment.unix(engagement.created.seconds).format('HH:mm')}</small>
                            </div>
                            <div className="engagement-details">
                                <h6>{engagement.title}</h6>
                                <p>{engagement.message}</p>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        /**
         * Otherwise just print a placeholder
         */
        return ('No leads recorded yet');
    }
}

export default Engagements;
import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import firebase from "firebase";
import moment from "moment";
import "./client.scss";

/**
 * Functional component to return the client row for the leaderboard
 */
export default function Client({ id, scope, update }) {
    const [name, setName] = useState("");
    const [progress, setProgress] = useState(0);
    const [kpiTarget, setKpiTarget] = useState(0);
    const [leads, setLeads] = useState([]);
    const [channels, setChannels] = useState({});
    const [leadsLoaded, setLeadsLoaded] = useState(false);

    /**
     * When the client ID is updated down the props
     */
    useEffect(() => {
        /**
         * Pull the clients document
         */
        const unsubscribe = db.doc(`clients/${id}`)
            .onSnapshot((clientDoc) => {
                /**
                 * Set the name into the state
                 */
                setName(clientDoc.data().name);
                setKpiTarget(Number(clientDoc.data().kpi_target));
            });
        /**
         * Remove the listener on unload
         */
        return () => unsubscribe();
    }, [id]);

    // console.log(scope,"SCOPE OF THIS ")
    /**
     * When the client ID or date ranges are updated
     */
    useEffect(() => {
        /**
         * Reset the leads in the state
         */
        setLeads([]);
        /**
         * Get the start and end of the month chosen as a unix timestamp
         */
        const rangeStart = moment(scope, "MM-YYYY").startOf("month").valueOf();
        const rangeEnd = moment(scope, "MM-YYYY").endOf("month").valueOf();
        /**
         * Convert these into firestore timestamps
         */
        const timestampStart = firebase.firestore.Timestamp.fromMillis(rangeStart);
        const timestampEnd = firebase.firestore.Timestamp.fromMillis(rangeEnd);
        /**
         * Setup a listener on the client's "leads" subcollection to stream changes
         */
        const unsubscribe = db.collection(`clients/${id}/leads`)
            .where("updated", ">=", timestampStart)
            .where("updated", "<=", timestampEnd)
            .onSnapshot((leadsSnap) => {
                /**
                 * Loop through the changes from the snapshot
                 */
                leadsSnap.docChanges().forEach(async (change) => {
                    /**
                     * Pull the type of lead from the changed document
                     */
                    const { type } = change.doc.data();
                    /**
                     * We only want to continue on appointment leads
                     */
                    if (type === "9_Appointment") {
                        /**
                         * Filter the action into the relevant update method
                         */
                        if (change.type === "added") {
                            /**
                             * Push the lead into the state
                             */
                            setLeads((leads) => [...leads, {
                                id: change.doc.id,
                                ...change.doc.data(),
                            }]);
                        } else if (change.type === "modified") {
                            /**
                             * Update the current lead in the state
                             */
                            setLeads((leads) => {
                                let currentLeads = [...leads];
                                for (let i in leads) {
                                    if (leads[i].id === change.doc.id) {
                                        currentLeads[i] = {
                                            id: change.doc.id,
                                            ...change.doc.data(),
                                        };
                                        break;
                                    }
                                }
                                return currentLeads;
                            });
                        }
                    }
                    /**
                     * Regardless of the appointment type here, otherwise it'll miss those leads that 
                     * are downgraded from appointment to a hot lead
                     */
                    if (change.type === "removed") {
                        /**
                         * Remove the lead from the array
                         */
                        setLeads((leads) => leads.filter((leadElement) => leadElement.id !== change.doc.id));
                    }
                    /**
                     * Set the leads loaded flag to true
                     */
                    setLeadsLoaded(true);
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, [id, scope]);

    /**
     * When the leads are updated
     */
    useEffect(() => {
        /**
         * Only progress if the leads have fully loaded, to save on resources
         */
        if (leadsLoaded) {
            /**
             * Setup a new array for storing the channels values
             */
            let newChannels = {};
            /**
             * Loop through each of the leads
             */
            leads.forEach((lead) => {
                /**
                 * Pull the "where" field from the lead
                 */
                const { where } = lead;
                /**
                 * Set the count into the array for this lead channel
                 */
                if (newChannels[`${where}`] > 0) {
                    newChannels[`${where}`] = newChannels[`${where}`] + 1;
                } else {
                    newChannels[`${where}`] = 1;
                }
            });
            /**
             * Push the updates into the state
             */
            setChannels(newChannels);
        }
    }, [leads, leadsLoaded]);

    /**
     * When the leads or kpiTarget target update
     */
    useEffect(() => {
        /**
         * Only progress if the leads have fully loaded, to save on resources
         */
        if (leadsLoaded) {
            /**
             * Get the current length of the leads array
             */
            const appointmentCount = leads.length || 0;
            /**
             * Calculate a progress
             */
            const currentProgress = Number((appointmentCount / kpiTarget) * 100);
            /**
             * Set the progress into the state
             */
            setProgress(currentProgress);
            update(id, currentProgress);
        }
    }, [leads, kpiTarget, leadsLoaded]);

    return (
        <div className="client-row" id={id}>
            <p className="client-name">{name}</p>
            <div className="kpi-progress">
                {/* The KPI breakdown is shown on hover */}
                <div className="lead-breakdown">
                    {Object.entries(channels).map((channel) => (
                        <p key={channel[0]}>{channel[0]}: {channel[1]}</p>
                    ))}
                </div>

                {/* Is there a progress present in state */}
                {(progress > 0) &&
                    <div className={[
                        "kpi-progress-bar",
                        progress >= 0 && progress <= 49 && "red",
                        progress >= 50 && progress <= 79 && "amber",
                        progress >= 80 && "green",
                    ].join(" ")}
                        style={{ width: (progress >= 100) ? "100%" : `${progress}%` }} >
                        <p>
                            {leads.length} Set ({progress?.toFixed(2)}%)
                        </p>
                    </div>
                }

                {/* If there is no progress on this client yet */}
                {(progress === 0) &&
                    <span className="no-kpi-value">Yet to register first appointment</span>
                }

                {/* kpiTarget target */}
                <span className="kpi-target">{kpiTarget}</span>
            </div>
        </div>
    );
}
import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import * as actionTypes from "../../store/actions";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "../../utils/router";
import { db, auth } from "../../utils/firebase";
import "./app.scss";

import Navigation from "../../components/navigation/navigation";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user: null,
        };
    }

    componentDidMount() {
        this.authObserve();
    }

    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    authObserve = () => {
        this.unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
            if (user) {
                db.doc(`users/${user.uid}`)
                    .get()
                    .then((userDoc) => {
                        if (userDoc.exists) {
                            this.loadStateWithUser(userDoc.data());
                            db.doc(`users/${user.uid}`).update({
                                last_signin: firebase.firestore.FieldValue.serverTimestamp(),
                            });
                        }
                    });
                this.setState({ loading: false });
            } else {
                this.setState({ no_login: true, loading: false, user: null });
            }
        });
    };

    loadStateWithUser(user) {
        this.props.loginSuccess({
            id: user.id,
            ...user,
        });
        this.setState({
            user: { id: user.id, ...user },
        });
    }

    render() {
        return (
            <BrowserRouter>
                {this.state.loading ? (
                    "loading"
                ) : (
                    <div id="app-splash">
                        {this.state.user && <Navigation   />}
                        <div id="app-screen">
                            <AppRouter loggedin={!this.state.no_login} user={this.state.user} />
                        </div>
                    </div>
                )}
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loginSuccess: (payload) => dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: payload }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
